<template>
  <div>
    <section class="section is-main-section">
      <h2 class="title">
        {{ title }}
      </h2>
      <hr />
      <div class="columns panel">
        <div class="column is-4">
          <b-field label="ວັນທີ" horizontal>
            <b-datepicker
              v-model="selectedDate"
              :locale="this.getDateLocale"
              icon="calendar-today"
            >
            </b-datepicker>
          </b-field>
        </div>
        <div class="column is-2">
          <b-button class="is-info" @click="initailData()" icon-left="magnify">
          </b-button>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="panel is-primary">
            <b-tabs v-model="activeTab" type="is-boxed">
              <b-tab-item label="ລາຍການທັງໝົດ">
                <div class="columns" v-if="sum">
                    <div class="column">
                      <span class="tag is-info is-light title is-4">
                        ₭
                        {{ sumDetail["LAK"] | formatnumber }}
                      </span>
                    </div>
                    <div class="column">
                      <span class="tag is-primary is-light title is-4">
                        ฿
                        {{ sumDetail["THB"] | formatnumber }}
                      </span>
                    </div>

                    <div class="column" v-if="models.length > 0">
                      <b-button
                        @click="exportSummaryExcel()"
                        size="is-medium"
                      >
                        <b-icon
                          icon="file-excel-outline"
                          style="margin-right: 0.5em"
                        ></b-icon>
                        ດາວໂຫຼດໄຟລ໌Excel
                      </b-button>
                    </div>
  
                  </div>
                <div class="table-container">
                <b-table 
                :mobile-cards="false"
                :loading="isLoading"
                :paginated="perPage < models.length"
                :per-page="perPage"
                :striped="true"
                :narrowed="true"
                :current-page="currentPage"
                :pagination-rounded="true"
                :data="models"  class="is-narrow is-size-7" style="white-space: nowrap;">
              <template v-for="column in detailColums">
                <b-table-column :key="column.id" v-bind="column">
                  <template v-slot="props" v-if="column.is_lak_or_thb" style="white-space: nowrap;">
                    {{ formatnumber(props.row[`${column.field}_lak`] ||  props.row[`${column.field}_thb`]) }}
                  </template>
                  <template v-slot="props" v-else >
                    {{ column.numeric? formatnumber(props.row[column.field]) :
                    (column.is_date? date(props.row[column.field]) : props.row[column.field])
                      }}
                  </template>
                </b-table-column>
              </template>
              <empty-table v-if="models.length == 0" :isLoading="isLoading" />
            </b-table>
                </div>
              </b-tab-item>
              <b-tab-item label="ລາຍການຍັງບໍ່ທັນໂອນ">
                <div class="column" style="padding: 1em">
                  <div class="columns" v-if="sum">
                    <div class="column">
                      <span class="tag is-info is-light title is-4">
                        ₭
                        {{ sum["LAK"] | formatnumber }}
                      </span>
                    </div>
                    <div class="column">
                      <span class="tag is-primary is-light title is-4">
                        ฿
                        {{ sum["THB"] | formatnumber }}
                      </span>
                    </div>
                    <div class="column is-3" v-if="manualClearing.length > 0">
                      <b-datepicker :mobile-native="false"
                              v-model="clearCODDate"
                              :locale="this.getDateLocale"
                              :min-date="minDate"
                              :max-date="maxDate"
                              @input="clearCODDateChange"
                              expanded
                              placeholder="ເລືອກວັນທີ"
                      >
                          <template v-slot:trigger>
                              <b-button
                                   icon-left="check-all"
                              label="ໂອນສຳເລັດ"
                              type="is-success" />
                          </template>
                      </b-datepicker>

                    </div>
                    <div class="column" v-if="toTransfer.length > 0">
                      <b-button
                        @click="exportExcelForIbank()"
                        size="is-medium"
                        class="is-danger is-light"
                      >
                        <b-icon
                          icon="file-excel-outline"
                          style="margin-right: 0.5em"
                        ></b-icon>
                        ໄຟລ໌ສຳລັບ i-Bank
                      </b-button>
                    </div>
                    <div class="column" v-if="manualClearing.length > 0">
                      <b-button
                        @click="setClearingStatus()"
                        size="is-medium"
                        class="is-info"
                      >
                        <b-icon
                          icon="transit-transfer"
                          style="margin-right: 0.5em"
                        ></b-icon>
                        ກຳລັງໂອນ
                      </b-button>
                    </div>

                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-table
                        :loading="isLoading"
                        :paginated="perPage < toTransfer.length"
                        :per-page="perPage"
                        :striped="true"
                        :hoverable="true"
                        default-sort="customer_id"
                        :data="toTransfer"
                        :current-page="currentPage"
                        :pagination-rounded="true"
                      >
            
                        <b-table-column label="#" v-slot="props">
                          {{ props.index * currentPage + 1 }}
                        </b-table-column>
                        <b-table-column label="ລູກຄ້າ" v-slot="props">
                          {{ props.row.customer_name }}
                        </b-table-column>
                        <b-table-column label="ເບີໂທ" v-slot="props">
                          {{ props.row.customer_tel }}
                        </b-table-column>

                        <b-table-column label="ຈຳນວນລາຍການ" v-slot="props" field="transaction" sortable>
                          {{ props.row.transaction }}
                        </b-table-column>
                        <b-table-column
                          label="ຈຳນວນເງິນ"
                          v-slot="props"
                          cell-class="has-text-right">
                          {{ (props.row.payment_ccy==="LAK"? props.row.sum_lak : props.row.sum_thb) | formatnumber }}
                        </b-table-column>
                        <b-table-column label="ສະກຸນເງິນ" v-slot="props">
                          {{ props.row.payment_ccy }}
                        </b-table-column>
                        <b-table-column
                          label="ເລກບັນຊີ"
                          v-slot="props"
                          cell-class="has-text-right"
                        >
                          {{ props.row.payment_ccy==="LAK"? props.row.account_lak : props.row.account_thb }}
                        </b-table-column>
                        <b-table-column label="ທະນາຄານ" v-slot="props">
                          {{ props.row.bankid}}
                        </b-table-column>
                        <b-table-column label="ຕົວເລືອກ" v-slot="props">
                          <b-checkbox
                            v-model="manualClearing"
                            :native-value="props.row.shipids"
                            type="is-success"
                          >
                          </b-checkbox>
                        </b-table-column>
                        <empty-table
                          v-if="toTransfer.length == 0"
                          :isLoading="isLoading"
                        />
                      </b-table>
                    </div>
                  </div>
                </div>
              </b-tab-item>
              <b-tab-item label="ລາຍການກຳລັງໂອນ">
                <div class="" style="padding: 1em">
                  <div class="columns" v-if="sumTransfering">
                    <div class="column">
                      <span class="tag is-info is-light title is-4">
                        ₭
                        {{ sumTransfering["LAK"] | formatnumber }}
                      </span>
                    </div>
                    <div class="column">
                      <span class="tag is-primary is-light title is-4">
                        ฿
                        {{ sumTransfering["THB"] | formatnumber }}
                      </span>
                    </div>
                    <div class="column is-3" v-if="transferFinish.length > 0">
                      <b-datepicker :mobile-native="false"
                              v-model="clearCODDate"
                              :locale="this.getDateLocale"
                              :min-date="minDate"
                              :max-date="maxDate"
                              @input="clearCODDateChange2"
                              expanded
                              placeholder="ເລືອກວັນທີ"
                      >
                          <template v-slot:trigger>
                              <b-button
                                   icon-left="check-all"
                              label="ໂອນສຳເລັດ"
                              type="is-success" />
                          </template>
                      </b-datepicker>

                       
                    </div>

                    <div class="column" v-if="transferInProgress.length > 0">
                      <b-field class="file is-warning">
                        <b-upload v-model="ibankfile" class="file-label">
                          <span class="file-cta">
                            <b-icon
                              class="file-icon"
                              icon="file-upload-outline"
                            ></b-icon>
                            <span class="file-label"
                              >ອັບໂຫຼດລາຍການຈາກ i-Bank</span
                            >
                          </span>
                        </b-upload>
                      </b-field>
                    </div>

                  </div>
                  <div class="columns">
                    <div class="column">
                       <b-table
                        :loading="isLoading"
                        :paginated="perPage < transferInProgress.length"
                        :per-page="perPage"
                        :striped="true"
                        :hoverable="true"
                        default-sort="customer_id"
                        :data="transferInProgress"
                        :current-page="currentPage"
                        :pagination-rounded="true"
                      >
            
                        <b-table-column label="#" v-slot="props">
                          {{ props.index * currentPage + 1 }}
                        </b-table-column>
                        <b-table-column label="ລູກຄ້າ" v-slot="props">
                          {{ props.row.customer_name }}
                        </b-table-column>
                        <b-table-column label="ເບີໂທ" v-slot="props">
                          {{ props.row.customer_tel }}
                        </b-table-column>

                        <b-table-column label="ຈຳນວນລາຍການ" v-slot="props" field="transaction" sortable>
                          {{ props.row.transaction }}
                        </b-table-column>
                        <b-table-column
                          label="ຈຳນວນເງິນ"
                          v-slot="props"
                          cell-class="has-text-right">
                          {{ (props.row.payment_ccy==="LAK"? props.row.sum_lak : props.row.sum_thb) | formatnumber }}
                        </b-table-column>
                        <b-table-column label="ສະກຸນເງິນ" v-slot="props">
                          {{ props.row.payment_ccy }}
                        </b-table-column>
                        <b-table-column
                          label="ເລກບັນຊີ"
                          v-slot="props"
                          cell-class="has-text-right"
                        >
                          {{ props.row.payment_ccy==="LAK"? props.row.account_lak : props.row.account_thb }}
                        </b-table-column>
                        <b-table-column label="ທະນາຄານ" v-slot="props">
                          {{ props.row.bankid}}
                        </b-table-column>
                        <b-table-column label="ຕົວເລືອກ" v-slot="props">
                          <b-checkbox
                            v-model="transferFinish"
                            :native-value="props.row.shipids"
                            type="is-success"
                          >
                          </b-checkbox>
                        </b-table-column>
                        <empty-table
                          v-if="transferInProgress.length == 0"
                          :isLoading="isLoading"
                        />
                      </b-table>
                    </div>
                  </div>
                </div>
              </b-tab-item>


              <b-tab-item label="ໃບແຈ້ງໂອນສຳລັບລູກຄ້າ">
                <div class="" style="padding: 1em">
                  <div class="columns">
                    <div class="column">
                       <b-table
                        :loading="isLoading"
                        :paginated="perPage < customerBill.length"
                        :per-page="perPage"
                        :striped="true"
                        :hoverable="true"
                        :default-sort="['transaction', 'desc']"
                        :data="customerBill"
                        :current-page="currentPage"
                        :pagination-rounded="true"
                      >
            
                        <b-table-column label="#" v-slot="props">
                          {{ props.index * currentPage + 1 }}
                        </b-table-column>
                        <b-table-column label="ວັນທີ" v-slot="props">
                          {{ props.row.delivery_date }}
                        </b-table-column>
                        <b-table-column label="ລູກຄ້າ" v-slot="props">
                          {{ props.row.customer_name }}
                        </b-table-column>
                        <b-table-column label="ເບີໂທ" v-slot="props">
                          {{ props.row.customer_tel }}
                        </b-table-column>
                        <b-table-column label="ຈຳນວນລາຍການ" v-slot="props"  field="transaction" sortable>
                          {{ props.row.transaction }}
                        </b-table-column>
                        <b-table-column
                          label="ຈຳນວນເງິນ"
                          v-slot="props"
                          cell-class="has-text-right">
                          {{ (props.row.payment_ccy==="LAK"? props.row.sum_lak : props.row.sum_thb) | formatnumber }}
                        </b-table-column>
                        <b-table-column label="ສະກຸນເງິນ" v-slot="props">
                          {{ props.row.payment_ccy }}
                        </b-table-column>
                        <b-table-column v-slot="props">
                          <b-tooltip label="ດາວໂຫລດ excel" 
                          type="is-warning"
                         position="is-left">
                            <b-button
                            class="is-small is-warning"
                            icon-left="file-download-outline"
                            @click="exportBill(props.row.customer_id)">
                            </b-button></b-tooltip>
                        </b-table-column>
                        <empty-table
                          v-if="customerBill.length == 0"
                          :isLoading="isLoading"
                        />
                      </b-table>
                    </div>
                  </div>
                </div>
              </b-tab-item>





            </b-tabs>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { get } from "vuex-pathify";
import EmptyTable from "../components/EmptyTable.vue";
import moment from "moment";
import XLSX from "xlsx";
import store from "@/store";
import helper from "@/helper";

export default {
  name: "Cod",
  components: { EmptyTable },
  computed: {
    user: get("user"), 
  },
  data() {
    return {
      minDate:new Date((new Date()).getFullYear() , (new Date()).getMonth(), (new Date()).getDate()-1),
      maxDate:new Date(),   
      activeTab: 0,
      currentPage: 1,
      perPage: 20,
      title: "ໂອນ COD",
      models: [],
      isShowModal: false,
      isLoading: false,
      selectedDate: null,
      excelFields: {
        accountx: "accountx",
        amount: "amount",
        name: "name",
        narative: "narative",
      },
      sumDetail: null,
      sum: null,
      sumTransfering: null,
      manualClearing: [],
      transferFinish: [],
      codTransfering: [],
      ibankfile: null,
      toTransfer:[],
      transferInProgress:[],
      customerBill:[],
      detailColums:[
        {field:'delivery_date',label:'ວັນທີ',is_date:true}
        ,{field:'code',label:'ລະຫັດ',}
        ,{field:'customer_name',label:'ຊື່ລູກຄ້າ',}
        ,{field:'customer_tel',label:'ເບີໂທ',}
        ,{field:'from_branch',label:'ຈາກສາຂາ',}
        ,{field:'to_branch',label:'ໄປສາຂາ',}
        ,{field:'cod_lak',label:'COD ກີບ',numeric:true}
        ,{field:'cod_thb',label:'COD ບາດ',numeric:true}
        ,{field:`paymen`,label:'ຊຳລະ COD', numeric:true, is_lak_or_thb:true}
        ,{field:'payment_ccy',label:'ຊຳລະເປັນສະກຸນ',}
        ,{field:`selftransfer`,label:'ໂອນກັນເອງ',numeric:true, is_lak_or_thb:true}
        ,{field:`discount`,label:'ສ່ວນລຸດ',numeric:true, is_lak_or_thb:true}
        ,{field:'cod_status',label:'ສະຖານະ',}
         
      ],
      clearCODDate: null,
    };
  },
  mounted() {
    this.selectedDate = new Date() ;
    this.selectedDate.setDate(new Date().getDate() - 1);
    this.initailData();
  },
  watch: {
    ibankfile: function () {
      //console.log(this.ibankfile.name);
      this.readIbankFile(this.resultHandle);
    },
    models: function () {
      this.sumDetail = {
        LAK: 0,
        THB: 0,
        USD: 0,
      };
      this.models.forEach((element) => {
        this.sumDetail[element.payment_ccy] += element.payment_ccy==="LAK"? element.paymen_lak * 1 :element.paymen_thb*1;
      });
    },
    toTransfer: function () {
      this.sum = {
        LAK: 0,
        THB: 0,
        USD: 0,
      };
      this.toTransfer.forEach((element) => {
        this.sum[element.payment_ccy] += element.payment_ccy==="LAK"? element.sum_lak * 1 :element.sum_thb ;
      });
    },
    transferInProgress: function () {
      this.sumTransfering = {
        LAK: 0,
        THB: 0,
        USD: 0,
      };
      this.transferInProgress.forEach((element) => {
        this.sumTransfering[element.payment_ccy] += element.payment_ccy==="LAK"? element.sum_lak * 1 :element.sum_thb ;
      });
    },
  },
  methods: {
    clearCODDateChange(date){
      //console.log(date);
       this.$buefy.dialog.confirm({
        title: "ຢືນຢັນວັນທີໂອນ",
        message: `ລາຍການທີ່ເລືອກໄດ້ໂອນສຳເລັດວັນທີ <b>${ moment(date).format("DD/MM/YYYY")}</b>`,
        confirmText: "ແມ່ນ, ຖືກຕ້ອງ",
        cancelText: "ບໍ່",
        type: "is-info",
        hasIcon: true,
        onConfirm: () => {
          this.clearCODDate=null;
          this.finishStatus(this.manualClearing, date);
          //console.log('send api');
        },
      });
    },
    clearCODDateChange2(date){
      //console.log(date);
       this.$buefy.dialog.confirm({
        title: "ຢືນຢັນວັນທີໂອນ",
        message: `ລາຍການທີ່ເລືອກໄດ້ໂອນສຳເລັດວັນທີ <b>${ moment(date).format("DD/MM/YYYY")}</b>`,
        confirmText: "ແມ່ນ, ຖືກຕ້ອງ",
        cancelText: "ບໍ່",
        type: "is-info",
        hasIcon: true,
        onConfirm: () => {
          this.clearCODDate=null;
          this.finishStatus(this.transferFinish.flat(), date);
          //console.log('send api');
        },
      });
    },
    initailData() {
      this.minDate=new Date(this.selectedDate.getFullYear() ,this.selectedDate.getMonth(), this.selectedDate.getDate());
      this.sum = {
        LAK: 0,
        THB: 0,
        USD: 0,
      };
      this.sumDetail = {
        LAK: 0,
        THB: 0,
        USD: 0,
      };
      this.sumTransfering = {
        LAK: 0,
        THB: 0,
        USD: 0,
      };
      this.getWaitingCOD();
      //this.getTransferingCOD();
    },
    resultHandle(result) {
      
      if (result.length > 0) {
        //let pids = [];
        result.forEach((i) => {
          const findResult = this.transferInProgress.filter(
            (t) =>
              t.description === i.desc.w &&
              t.payment_ccy === i.ccy &&
              (t.sum_lak === i.debit.v || t.sum_thb === i.debit.v )
          );
          
          if (findResult.length == 1) {
            //this.transferFinish.push(findResult[0].pids);
            //pids.push(findResult[0].pids);
            this.setClearedStatusFromIBankFile(findResult[0].shipids, i.billno.w);
          }
        });
        //const _pids = pids.flat();
        //this.updateShipmentCODStatus(_pids);
        this.initailData();
        this.manualClearing = [];
        this.transferFinish = [];
      }
    },
    readIbankFile(handle) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];

        const result = [];
        let isStart = false;
        const _ccel = worksheet[`D8`];
        const CCY = _ccel
          ? _ccel.w.startsWith("ກີບ")
            ? "LAK"
            : _ccel.w.startsWith("ບາດ")
            ? "THB"
            : "USD"
          : "";
        //console.log(CCY);
        for (let i = 1; i < 10240; i++) {
          const desired_cell = worksheet[`A${i}`];
          const desired_value = desired_cell ? desired_cell.v : undefined;
          if (desired_value !== undefined) {
            if (desired_value.startsWith("ຍອດຍົກມາ")) {
              //console.log('End row');
              isStart = false;
              break;
            }

            if (isStart) {
              //console.log(desired_value);
              const statement = {
                date: worksheet[`A${i}`],
                billno: worksheet[`B${i}`],
                desc: worksheet[`C${i}`],
                debit: worksheet[`D${i}`],
                ccy: CCY,
              };
              //console.log(statement)
              if (statement.debit.v > 0 && statement.desc.w.includes("|COD|")) {
                result.push(statement);
              }
            }
            if (desired_value.startsWith("ວັນທີ")) {
              //console.log('Start row');
              isStart = true;
            }
          }
        }

        handle(result);
      };

      reader.readAsArrayBuffer(this.ibankfile);
    },
     
    getWaitingCOD() {
      const d = moment(this.selectedDate).format("YYYY-MM-DD");
      axios.get(`cod/getsummary/${d}`).then((r) => {
        this.models = r.cod.map(e=>{
           if(e.cod_status==="transfered") e.cod_status="ໂອນແລ້ວ"
           if(e.cod_status==="transfering") e.cod_status="ກຳລັງໂອນ"
           if((e.selftransfer_lak && e.selftransfer_lak)>0 || (e.selftransfer_thb && e.selftransfer_thb>0)) e.cod_status="ໂອນກັນເອງ"
          return e;
        });
        this.customerBill=r.customerBill;
        this.toTransfer=r.totranfer.map(e=>{
          if(e.account_lak && e.account_lak.includes('|')){
            const acc=e.account_lak.split('|');
            e.account_lak=acc[0];
            e.bankid=acc[1];
          }
          if(e.account_thb && e.account_thb.includes('|')){
            const acc=e.account_thb.split('|');
            e.account_thb=acc[0];
            e.bankid=acc[1];
          }
           
          return e;
        });
        this.transferInProgress=r.tranfering.map(e=>{
          if(e.account_lak && e.account_lak.includes('|')){
            const acc=e.account_lak.split('|');
            e.account_lak=acc[0];
            e.bankid=acc[1];
          }
          if(e.account_thb && e.account_thb.includes('|')){
            const acc=e.account_thb.split('|');
            e.account_thb=acc[0];
            e.bankid=acc[1];
          }
        const dateStr = (new moment(e.delivery_date)).format("YYYY-MM-DD");
        const description= `ກຽງໄກຂົນສົ່ງດ່ວນ|COD|${dateStr}|${e.customer_id}|${e.customer_name}|${e.customer_tel}`;
       e.description=description;
           
          return e;
        });
         
      });
    },
    setClearingStatus(shipids) {
      if (!shipids) shipids = this.manualClearing.flat();
      if((Array.isArray(shipids) && shipids.length===1 && shipids[0].includes(',')) || (!Array.isArray(shipids) && shipids.includes(','))){
        shipids=shipids[0].split(',');
      }
      //console.log(shipids);
      axios.post(`cod/set-transfering`, { shipids: shipids }).then((res) => {
        console.log(res);
        this.initailData();
      });
      this.manualClearing = [];
    },
    setClearedStatusFromIBankFile(shipids, refs,ccy) {
       shipids =( shipids.split(",")).flat();
      axios.post(`cod/set-codref`, { ref: refs, shipids: shipids,ccy:ccy }).then((res) => {
        console.log(res);
        this.initailData();
      });
      this.manualClearing=[];
    },
     
    finishStatus(shipids,date) {
      if (!shipids) shipids = this.transferFinish.flat();
      if((Array.isArray(shipids) && shipids.length===1 && shipids[0].includes(',')) || (!Array.isArray(shipids) && shipids.includes(','))){
        shipids=shipids[0].split(',');
      }
      axios.post(`cod/manual-transfer`, { shipids: shipids ,transferDate:date}).then((res) => {
        console.log(res);
        this.initailData();
      });
      
      this.manualClearing = [];
      this.transferFinish = [];
    },
    //getDescription: function(_model) {
    //  const dateStr=(new moment(_model.created_date)).format('YYYY-MM-DD')
    //  return  Function('return function(data,dateStr){ return `' + this.presetDesc + '`}')()(_model,dateStr);
    //},
    updateCODClearDone(shipId) {
      const _date = moment(this.selectedDate).format("YYYY-MM-DD");
      axios
        .post(`cod/update/${helper.COD_CLEARING_STATUS.TRANSFERED}`, {
          date: _date,
          shipId: shipId,
        })
        .then((res) => {
          console.log(res);
          const nModels = this.models.filter(
            (i) => !this.getGroup.includes(i.account)
          );
          this.getGroup = [];
          this.modelTransferings = nModels;
        });
    },
    getUserBranchInfo() {
      return store.getters.getUserIdBranchInfo;
    },
    exportExcelForIbank() {
      this.generateExcelFile(
        this.toTransfer.filter((m) => m.payment_ccy === "LAK" && m.bankid === "BCEL")
      ,"LAK" );
      this.generateExcelFile(
        this.toTransfer.filter((m) => m.payment_ccy === "THB" && m.bankid === "BCEL")
      ,"THB");

      const shipids = this.toTransfer
        .filter((m) => m.bankid === "BCEL")
        .reduce((s, c) => {
          s.push(c.shipids);
          return s;
        }, []);
      //console.log(shipids.flat());
      this.setClearingStatus(shipids.flat());
    },
    generateExcelFile(exportData,ccy) {
      if (exportData === undefined || exportData.length < 1) return;
      //Create workBook
      const workBook = XLSX.utils.book_new();
      /* worksheet  data */
      const ws_data = [
        //["0101200xxxxxxxx001",1000,"Mr. Test (ACCOUNT NAME)","Description 1"],
      ];
    
      for (const item of exportData) {
        const dateStr = (new moment(item.delivery_date)).format("YYYY-MM-DD");
        const description= `ກຽງໄກຂົນສົ່ງດ່ວນ|COD|${dateStr}|${item.customer_id}|${item.customer_name}|${item.customer_tel}`;
        ws_data.push([ ccy==="LAK"?item.account_lak:item.account_thb, ccy==="LAK"?item.sum_lak:item.sum_thb, item.customer_name, description]);
        
      }
      /* create worksheet */
      const ws = XLSX.utils.aoa_to_sheet(ws_data);
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(workBook, ws, "Sheet1");
      /* Write workbook to file */
      const _date = moment(this.selectedDate).format("YYYY_MM_DD_HHmmss");
      const _ub = this.getUserBranchInfo();
      XLSX.writeFile(workBook, `COD_${ccy}_FILE_UPLOAD_${_date}_${_ub}.xlsx`);
    },
    exportSummaryExcel() {
       if (this.models === undefined || this.models.length < 1) return;
      //Create workBook
      const workBook = XLSX.utils.book_new();
      /* worksheet  data */
      const ws_data = [
        [
          "ວັນທີສ້າງລາຍການ",
          "ວັນທີລູກຄ້າປາຍທາງຮັບເຄື່ອງ",
          "ລະຫັດລາຍການ",
          "ຊື່ລູກຄ້າ",
          "ເບີໂທ",
          "ຊື່ຜູ້ຮັບ",
          "ເບີໂທຜູ້ຮັບ",
          "ປະເພດເຄື່ອງ",
          "ຄ່າຝາກ ກີບ",
          "ຄ່າຝາກ ບາດ",
          "COD ກິບ",
          "COD ບາດ",
          "ຊຳລະເປັນກີບ",
          "ຊຳລະດ້ວຍບາດ",
          "ໂອນກັນເອງ ກີບ",
          "ໂອນກັນເອງ ບາດ",
          "ສ່ວນລຸດ ກີບ",
          "ສ່ວນລຸດ ບາດ",
          "ເລກບັນຊີກີບ","ທະນາຄານ",
          "ເລກບັນຊີບາດ","ທະນາຄານ",
        ],
      ];

      for (const item of this.models ) {
        let bankid='';
        if(item.account_lak && item.account_lak.includes('|')){
            const acc=item.account_lak.split('|');
            item.account_lak=acc[0];
            bankid=acc[1];
        }

        let bankidthb='';
        if(item.account_thb && item.account_thb.includes('|')){
            const acc=item.account_thb.split('|');
            item.account_thb=acc[0];
            bankidthb=acc[1];
        }
        ws_data.push([
          moment(item.created_date).format("YYYY-MM-DD HH:mm:ss"),
          moment(item.delivery_date).format("YYYY-MM-DD HH:mm:ss"),
          item.code,
          item.customer_name,
          item.customer_tel,
          item.receivername,
          item.receivertel,
          item.parceltype,
          item.amount,
          item.amount_thb,
          item.cod_lak,
          item.cod_thb,
          item.paymen_lak,
          item.paymen_thb,
          item.selftransfer_lak,
          item.selftransfer_thb,
          item.discount_lak,
          item.discount_thb,
          item.account_lak,
          bankid,
          item.account_thb,
          bankidthb
        ]);
      }
      /* create worksheet */
      const ws = XLSX.utils.aoa_to_sheet(ws_data);
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(workBook, ws, "Sheet1");
      /* Write workbook to file */
      const _date = moment(this.selectedDate).format("YYYY_MM_DD_HHmmss");
      const _ub = this.getUserBranchInfo();
      XLSX.writeFile(workBook, `COD_${_date}_${_ub}.xlsx`);
    },
    exportBill(customer_id){
      console.log(customer_id);
      /* worksheet  data */
      const result=this.models.filter(e=>e.customer_id===customer_id);
      const customer_tel=result[0].customer_tel;
      let account_lak='';
      let account_thb='';
      let account_lakbankid='';
      let account_thbbankid='';

        if(result[0].account_lak && result[0].account_lak.includes('|')){
            const acc=result[0].account_lak.split('|');
            account_lak=acc[0];
            account_lakbankid=acc[1];
          }
          if(result[0].account_thb && result[0].account_thb.includes('|')){
            const acc=result[0].account_thb.split('|');
            account_thb=acc[0];
            account_thbbankid=acc[1];
          }


      const ws_data = [];
      ws_data.push(["ໃບສະຫຼຸບຍອດໂອນສຳລັບລູກຄ້າ ກຽງໄກ"]);   
      ws_data.push(["","ລະຫັດ",result[0].customer_code,result[0].customer_name,"","","",result[0].customer_tel]);   
      ws_data.push(["","","ເລກບັນຊີ",account_lak,"","","",account_lakbankid]);   
      ws_data.push(["","","",account_thb,"","","",account_thbbankid]);   
      ws_data.push(["#","ເລກບີນ","ວັນທີສົ່ງ","ວັນທີຮັບເຄື່ອງ","ປາຍທາງ","ຊື່ລູກຄ້າ","ກີບ","ບາດ","ໝາຍເຫດ"]); 
      //......data loop
      let r=1;
      let sum_lak=0;
      let sum_thb=0;
      const start_row=4;
      const merge = [{s: { r: 0, c: 0 },e: { r: 0, c: 8 } },
                      {s: { r: 1, c: 7},e: { r: 1, c: 8}},
                      {s: { r: 2, c: 2 },e: { r: 3, c: 2}},
                      {s: { r: 1, c: 3 },e: { r: 1, c: 6 }},
                      //{s: { r: 4, c: 2 },e: { r: 4, c: 4 }},
                      {s: { r: 2, c: 3 },e: { r: 2, c: 6 }},
                      {s: { r: 3, c: 3 },e: { r: 3, c: 6 }},
                      
                        ];
      result.forEach(e=>{
        let remark='';
        if((e.selftransfer_lak && e.selftransfer_lak>0) || (e.selftransfer_thb && e.selftransfer_thb >0)){
          remark='ໂອນກັນເອງ';
        } 
        //merge.push({s: { r: r+start_row, c: 3 },e: { r: r+start_row, c: 4 } });
         ws_data.push([r,e.code,
         new moment(e.created_date).format("DD/MM/YYYY"),  new moment(e.delivery_date).format("DD/MM/YYYY")
         ,`${e.to_branch}-${e.to_branch_name}`
         ,`${e.receivertel}-${e.receivername}`,e.paymen_lak,e.paymen_thb,remark]);
         sum_lak +=e.paymen_lak *1;
         sum_thb +=e.paymen_thb *1;
         r++;
         remark='';
      });
      merge.push({s: { r: r+start_row, c: 2 },e: { r: r+start_row, c: 5 } });
  const cod_transfer_date = result[0].cod_transfer_date ? new moment(result[0].cod_transfer_date).format("DD/MM/YYYY") :new moment(result[0].delivery_date).format("DD/MM/YYYY");
      ws_data.push(["","","ວັນທີແຈ້ງໂອນ: "+ new moment(new Date()).format("DD/MM/YYYY")+", ວັນທີໂອນ: "+ cod_transfer_date,"","","",sum_lak,sum_thb]);   
  console.log(result);
      const workBook = XLSX.utils.book_new();
       /* create worksheet */
      const ws = XLSX.utils.aoa_to_sheet(ws_data);
       ws["!merges"] = merge;
       /*const COLOR_SPEC={ auto: 1};
       const BORDER_STYLE="thin";
       //const table={s: { r: 0, c: 0},e: { r: r, c: 8 }} ;
       ws["A1"].s = {									// set the style for target cell
                      alignment:{
                        vertical:	"center",
                      horizontal:	"left" 
                      },
                     border:{	top:{ style: BORDER_STYLE, color: COLOR_SPEC },
                              bottom:{ style: BORDER_STYLE, color: COLOR_SPEC },
                              left:{ style: BORDER_STYLE, color: COLOR_SPEC },
                              right:{ style: BORDER_STYLE, color: COLOR_SPEC }
                              }
                    };
       */
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(workBook, ws, "Sheet1");
      /* Write workbook to file */
      const _date = moment(this.selectedDate).format("YYYY_MM_DD_HHmmss");
      XLSX.writeFile(workBook, `COD_FOR_CUSTOMER_${customer_tel}_${customer_id}_${_date}.xlsx`);
    },
    groupBy(array, key) {
      const _arr = array.reduce((_res, c) => {
        if (!_res[c[key]]) {
          _res[c[key]] = [];
          _res[c[key]].push(c);
          _res[c[key]][0].count = 1;
        } else {
          _res[c[key]][0].amount += c.amount;
          _res[c[key]][0].count++;
          if (c.id === undefined) {
            console.log(c);
          }
          _res[c[key]][0].pids.push(c.pid);
          _res[c[key]][0].ids.push(c.id);
        }
        return _res;
      }, {});
      const result = [];
      for (const s in _arr) {
        result.push(..._arr[s]);
      }
      //console.log(result);
      return result;
    },
  },
};
</script>